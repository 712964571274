<template>
  <div
    :class="[cardStyle, addShadow]"
    :style="{ marginBottom: marginBotValue + 'px' }"
    @click="openImage"
  >
    <img :class="clickableImg" :src="imgUrl" />
  </div>
</template>

<script>
export default {
  props: [
    "margBot",
    "margBotTab",
    "margBotMob",
    "imgUrl",
    "cardColor",
    "shadows",
    "clickable",
  ],

  computed: {
    cardStyle() {
      if (this.cardColor === "white") {
        return "imgCard_white";
      }
    },
    addShadow() {
      if (this.shadows) {
        return "imgCard_shadow";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
    clickableImg() {
      // console.log(this.clickable);
      if (this.clickable) {
        console.log(this.clickable);
        return "clickable";
      }
    },
  },
  methods: {
    openImage() {
      this.$emit("openImage");
    },
  },
};
</script>

<style scoped lang='sass'>
div
  border: 1px solid #939393
  border-radius: 16px
  padding: 24px
  img
    width: 100%
    height: 100%
    border: 1px solid #A7ABAF
    border-radius: 8px

.imgCard_white
  background-color: #FFF

.imgCard_shadow
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.1)

.clickable
  cursor: pointer

@media screen and (max-width: 1200px)
  div
    padding: 12px

@media screen and (max-width: 768px)
  div
    padding: 6px
</style>


