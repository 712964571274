<template>
  <div :style="{ marginBottom: marginBotValue + 'px' }">
    <p :class="textColor">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "padBotMob", "color"],
  computed: {
    textColor() {
      if (this.color === "black") {
        return "black__text";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200) {
        return this.margBotTab;
      }
    },
  },
};
</script>

<style scoped lang="sass">
p
  font-weight: 700
  font-size: 32px
  line-height: 48px

.yellow__text
  color: #F9AF34

.black__text
  color: #000000

@media screen and (max-width: 1200px)
  p
    font-weight: 700
    font-size: 24px
    line-height: 32px

@media screen and (max-width: 767px)
  p
    font-weight: 700
    font-size: 20px
    line-height: 28px
    overflow-wrap: break-word
</style>