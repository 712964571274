<template>
  <div :style="{ marginBottom: marginBotValue + 'px' }">
    <img :src="imgUrl" />
  </div>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "margBotMob", "imgUrl"],
  computed: {
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
};
</script>

<style scoped lang='sass'>
.tsi__aparts__plan
  img
    position: relative
    top: -100px
    left: -15px

@media screen and (max-width: 1200px)
  .tsi__aparts__plan
    img
      top: 0
      left: 0

@media screen and (max-width: 767px)
  .tsi__aparts__plan
    img
      top: 0
</style>


