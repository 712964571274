<template>
	<div class="formBlockContainer">
		<div :class="[{'container' : type == '2'}, {'container2' : type != '2'}]">

			<transition name="slide">
				<div class="formBlockSuccess color-white" id="form" v-if="success" key="success">
					<div class="formBlockSuccess__left">
						<div class="formBlockSuccess__header">
							<p class="formBlockSuccess--title">Спасибо за обращение!</p>
						</div>
						<a class="openForm hover-red" @click="openForm">Оставьте заявку еще раз</a>
					</div>
					<div class="formBlockSuccess__right">
						<img :src="require(`@/assets/img_new/check.png`)" alt="">
					</div>
				</div>
				<div class="color-white" :class="[{blockLeftTitle2: type != '2'}, {blockLeftTitle: type == '2'}]" v-else>
					<div :class="[{blockLeftTitle2__left: type != '2'}, {blockLeftTitle__left: type == '2'}]">
						<div class="mainTitle2">
							<p class="h2">Обсудить проект</p>
							<p class="color-gray mainTitle2--desc color-white">Оставьте заявку и мы с вами свяжемся в течение часа</p>
						</div>
					</div>
					<div :class="[{blockLeftTitle2__right: type != '2'}, {blockLeftTitle__right: type == '2'}]">
						<form action="">
							<div class="form__items">
								<div class="form__item--double">
									<div class="form__item">
										<input type="text" class="required" placeholder="Ваше имя" data-mask="singlename" v-model.lazy="formValues.name" autocomplete="nope">
										<span class="validation-error"></span>
									</div>
									<div class="form__item">
										<input type="tel" inputmode="numeric" class="required" placeholder="Телефон" data-mask="phone" v-model.lazy="formValues.phone" autocomplete="nope">
										<span class="validation-error"></span>
									</div>
									<div class="form__item">
										<input type="text" class="required" placeholder="E-mail" data-mask="email" v-model.lazy="formValues.email" autocomplete="nope">
										<span class="validation-error"></span>
									</div>
									<div class="form__item select">
										<input type="text" class="" name="budget" v-model.lazy="formValues.budget" autocomplete="nope">
										<div class="select__header" data-defaultText="Ваш примерный бюджет">
											Ваш примерный бюджет
										</div>
										<div class="select__body">
											<VuePerfectScrollbar class="select__items" :settings="scrollSettings">
												<div class="select__item" data-value="до 500 тыс.руб" data-text="до 500 тыс.руб">
													<p class="select__item--title">до 500 тыс.руб</p>
												</div>
												<div class="select__item" data-value="от 500 тыс.руб до 1.5 млн.руб" data-text="от 500 тыс.руб до 1.5 млн.руб">
													<p class="select__item--title">от 500 тыс.руб до 1.5 млн.руб</p>
												</div>
												<div class="select__item" data-value="свыше 1.5 млн.руб" data-text="свыше 1.5 млн.руб">
													<p class="select__item--title">свыше 1.5 млн.руб</p>
												</div>
											</VuePerfectScrollbar>
										</div>
										<span class="validation-error"></span>
									</div>
								</div>
								<div class="form__item">
									<textarea class="" placeholder="Опишите проект своими словами" v-model.lazy="formValues.description"></textarea>
									<span class="validation-error"></span>
								</div>
								<div class="form__item mb0 fileInput--up multiple">
									<div class="fileInput new">
										<input type="file" multiple="" data-mask="file">
										<a class="fileInput--text"><span class="fileInput--add"><span class="icon-plus2"></span></span>Добавить документ</a>
										<div class="fileInput__progress"></div>
									</div>
									<span class="validation-error">Обязательно</span>
								</div>
							</div>
							<div class="form__button buttonWithText">
								<a class="button button--red submit" @click="submit">Отправить</a>
								<p class="fs14">
									Отправляя заявку, вы соглашаетесь<br> с политикой конфиденциальности
								</p>
							</div>
						</form>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'

	export default {
		props: ['type'],
		data: () => ({
			success: false,
			formValues: {
				formName: 'feedback',
				name: '',
				phone: '',
				email: '',
				budget: '',
				description: '',
				files: [],
				page: ''
			},
			scrollSettings: {
				wheelPropagation: false
			}
		}),
		mounted(){
			this.$el.querySelectorAll('input').forEach(input => {
				initValidator(input);
			});
			this.$el.querySelectorAll('textarea').forEach(input => {
				initValidator(input);
			});
		},
		methods: {
			submit: function(e){
				var comp = this;
				e.preventDefault();
				var form = e.target.closest("form");
				var inputes = form.getElementsByTagName("input");
				var textareas = form.getElementsByTagName("textarea");
				
				for (var index = 0; index < inputes.length; index++) {
					var input = inputes[index];
					if((input.offsetWidth > 0 && input.offsetHeight > 0) || (input.closest('.select') && input.closest('.select').offsetWidth > 0 && input.closest('.select').offsetHeight > 0)){
						validate(input);
					}
				}
				
				for (var index = 0; index < textareas.length; index++) {
					var input = textareas[index];
					if(input.offsetWidth > 0 && input.offsetHeight > 0){
						validate(input);
					}
				}

				if($.data(form.getElementsByClassName("fileInput--up")[0], "files")){
					$.data(form.getElementsByClassName("fileInput--up")[0], "files").forEach(function(el, index) {
						comp.formValues.files.push(el.name);
					});
				}
				
				comp.formValues.page = comp.$router.app._route.path;


				for (var index = 0; index < form.elements.length; index++) {
					var input = form.elements[index];
					if(input.getAttribute("type") == "hidden"){
						comp.formValues[input.getAttribute("name")] = input.value;
					}
				}


				if(!form.getElementsByClassName("error").length){
					var data = new FormData();
					data.append("json", JSON.stringify(comp.formValues));

					const request = new XMLHttpRequest();
					const url = "/local/include/ajax/feedback.php";

					request.responseType = "json";
					request.open("POST", url);
					
					request.addEventListener("readystatechange", () => {
 
						if (request.readyState === 4 && request.status === 200) {
							let obj = request.response;
							comp.success = true;
							setTimeout(function(){
								doScrolling(e.target.closest(".formBlock").offsetTop, 400);
							}, 400);
						}
					});
					 
					request.send(data);
				}
			},
			openForm: function(e){
				var comp = this;
				var form = e.target.closest(".formBlockContainer");
				var top = form.closest(".fullPageSectionContainer").getBoundingClientRect().top + document.documentElement.scrollTop;

				comp.success = false;
				var inputes = form.getElementsByTagName("input");
				
				for (var index = 0; index < inputes.length; index++) {
					var input = inputes[index];
					if(input.attributes.type.value == "text"){
						input.value = "";
						input.closest('.form__item').classList.remove("error");
					} else if(input.attributes.type.value == "file"){
						if(input.closest(".fileInput").getElementsByClassName("fileInputFile--delete").length){
							input.closest(".fileInput").getElementsByClassName("fileInputFile--delete").forEach( function(element, index) {
								element.click();
							});
						}
					}
				}

				comp.formValues.name = '';
				comp.formValues.phone = '';
				comp.formValues.email = '';
				comp.formValues.budget = '';
				comp.formValues.description = '';
				comp.formValues.files = [];

				setTimeout(function(){
					comp.$el.querySelectorAll('input').forEach(input => {
						initValidator(input);
					});
					comp.$el.querySelectorAll('textarea').forEach(input => {
						initValidator(input);
					});
				}, 400);

				doScrolling(top, 400);
			}
		},
		components: {
			VuePerfectScrollbar
		}
	}
</script>
