<template>
  <div
    class="container"
    :style="{
      paddingTop: paddingTopValue + 'px',
      paddingBottom: paddingBotValue + 'px',
      marginBottom: marginBotValue + 'px',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [
    "padTop",
    "padBot",
    "margBot",
    "padTopTab",
    "padBotTab",
    "margBotTab",
    "padTopMob",
    "padBotMob",
    "margBotMob",
  ],
  computed: {
    paddingTopValue() {
      if (window.innerWidth > 1200) {
        return this.padTop;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.padTopTab;
      }
      if (window.innerWidth < 768) {
        return this.padTopMob;
      }
    },
    paddingBotValue() {
      if (window.innerWidth > 1200) {
        return this.padBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.padBotTab;
      }
      if (window.innerWidth < 767) {
        return this.padBotMob;
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.container
  position: relative
  width: calc(100% - 80px)
  max-width: 1360px
  margin: 0 auto

@media screen and (max-width: 1200px)
  .container
    width: calc(100% - 80px)
    position: relative
    max-width: 1360px
</style>

