<template>
  <TsiContent>
    <TsiBanner></TsiBanner>
    <TsiSecond></TsiSecond>
    <TsiPodbor></TsiPodbor>
    <TsiCard></TsiCard>
    <TsiComplex></TsiComplex>
    <TsiAparts></TsiAparts>
    <TsiOtherPages></TsiOtherPages>
    <TsiLast></TsiLast>
    <section
      class="fullPageSectionContainer setMenuColor"
      id="feedBackForm"
      data-menu-color="light"
    >
      <div class="fullPageSection">
        <div
          class="fullPageSection--back"
          style="background-color: #262e39"
        ></div>
        <Feedback :type="2"></Feedback>
      </div>
    </section>
  </TsiContent>
</template>

<script>
import TsiContent from "./UI/TsiContent.vue";
import TsiBanner from "./components/TsiBanner.vue";
import TsiSecond from "./components/TsiSecond.vue";
import TsiPodbor from "./components/TsiPodbor.vue";
import TsiCard from "./components/TsiCard.vue";
import TsiComplex from "./components/TsiComplex.vue";
import TsiAparts from "./components/TsiAparts.vue";
import TsiOtherPages from "./components/TsiOtherPages.vue";
import TsiLast from "./components/TsiLast.vue";
import Feedback from "@/components/new//Forms/Feedback";
export default {
  components: {
    TsiBanner,
    TsiContent,
    TsiSecond,
    TsiPodbor,
    TsiCard,
    TsiComplex,
    TsiAparts,
    TsiOtherPages,
    TsiLast,
    Feedback,
  },
  metaInfo: {
    title:
      "Разработка сайта-каталога недвижимости для крупного застройщика Республики Татарстан.",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Подробная информация о выполненном проекте и об этапах работ по созданию сайта для Специализированного застройщика «ТСИ».",
      },
      {
        vmid: "og:title",
        property: "og:title",
        content:
          "Разработка сайта-каталога недвижимости для крупного застройщика Республики Татарстан.",
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Подробная информация о выполненном проекте и об этапах работ по созданию сайта для Специализированного застройщика «ТСИ».",
      },
    ],
  },
};
</script>

