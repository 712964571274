<template>
  <div class="backgorund-dark" style="position: relative">
    <img
      class="decor__complex"
      :src="require('@/assets/img_new/tsi/tsi_complex_decor.svg')"
    />
    <TsiContainer
      :padTop="120"
      :padTopTab="90"
      :padTopMob="60"
      :padBot="120"
      :padBotTab="90"
      :padBotMob="60"
    >
      <TsiText80
        :margBot="60"
        :margBotTab="40"
        :margBotMob="40"
        :color="'yellow'"
        >Жилой комплекс</TsiText80
      >
      <TsiRightBlock :margBot="30" :margBotTab="24" :margBotMob="12">
        <TsiText16 :margBot="60" :margBotTab="40" :margBotMob="40">
          На сайте отображается список жилых комплексов «ТСИ»,<br>
          с преимуществами жизни, стоимостями, видами планировок.
        </TsiText16>
        <TsiText20 :color="'gray'"> Детальное отображение </TsiText20>
      </TsiRightBlock>
      <TsiBaseImage
        :imgUrl="require('@/assets/img_new/tsi/tsi.png')"
      ></TsiBaseImage>
    </TsiContainer>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiRightBlock from "../UI/TsiRightBlock.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText20 from "../UI/TsiText20.vue";
import TsiText80 from "../UI/TsiText80.vue";
export default {
  components: {
    TsiContainer,
    TsiText16,
    TsiText20,
    TsiText80,
    TsiRightBlock,
    TsiBaseImage,
  },
};
</script>

<style scoped lang="sass">
.decor__complex
  position: absolute
  top: -11px
  right: 0
  width: 430px

@media screen and (max-width: 1200px)
  .decor__complex
    display: none
</style>