<template>
  <p :class="textColor" :style="{ marginBottom: marginBotValue + 'px' }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "margBotMob", "color"],
  computed: {
    textColor() {
      if (this.color === "black") {
        return "black__text";
      }
      if (this.color === "yellow") {
        return "yellow__text";
      }
      if (this.color === "gray") {
        return "gray__text";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
};
</script>

<style scoped lang="sass">
p
  font-weight: 700
  font-size: 20px
  line-height: 28px
.gray__text
  color: #8C8C8C

@media screen and (max-width: 768px)
  p
    font-style: normal
    font-weight: 700
    font-size: 18px
    line-height: 24px
</style>