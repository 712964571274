<template>
  <div class="backgorund-dark">
    <TsiContainer
      :padTop="120"
      :padTopTab="90"
      :padTopMob="60"
      :padBot="120"
      :padBotTab="90"
      :padBotMob="60"
    >
      <TsiText80
        :margBot="60"
        :margBotTab="40"
        :margBotMob="40"
        :color="'yellow'"
        >Интеграции</TsiText80
      >
      <div class="card__row">
        <div class="card__col">
          <div
            class="card__item wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0s"
          >
            <img
              class="card__item__logo"
              :src="require('@/assets/img_new/tsi/tsi_amo_icon.svg')"
            />
            <TsiText16>
              AMO CRM — все заявки пользователей с любых форм сайта попадают в
              CRM, где отдел продаж «ТСИ» их обрабатывает и связывается с
              клиентами.
            </TsiText16>
          </div>
        </div>
        <div class="card__col">
          <div
            class="card__item wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <img
              class="card__item__logo"
              :src="require('@/assets/img_new/tsi/tsi_1C_logo.svg')"
            />
            <TsiText16>
              1С — синхронизация в режиме online списка жилых комплексов, списка
              квартир, параметры квартир, расположение на генеральном плане, на
              этаже.
            </TsiText16>
          </div>
        </div>
      </div>
      <TsiText80
        :margBot="60"
        :margBotTab="40"
        :margBotMob="40"
        :color="'yellow'"
        >Адаптивность</TsiText80
      >
      <TsiRightBlock>
        <TsiText16 :margBot="60" :margBotTab="60" :margBotMob="40"
          >Весь функционал сайта и пользовательский интерфейс доступен<br />
          как с мобильных устройств и планшетов, так и с компьютера.
        </TsiText16>
        <TsiText20
          :color="'gray'"
          :margBot="24"
          :margBotTab="24"
          :margBotMob="12"
          >Desktop 1440 px.</TsiText20
        >
        <TsiBaseImage
          :imgUrl="require('@/assets/img_new/tsi/tsi_adaptive.png')"
          :cardColor="'white'"
          :margBot="60"
          :margBotTab="40"
          :margBotMob="40"
        ></TsiBaseImage>
        <div class="flex__row">
          <div>
            <TsiText20
              :color="'gray'"
              :margBot="24"
              :margBotTab="24"
              :margBotMob="12"
              >Tablet 768 px.</TsiText20
            >
            <TsiBaseImage
              :cardColor="'white'"
              :imgUrl="require('@/assets/img_new/tsi/tsi_adaptive_tablet.png')"
            ></TsiBaseImage>
          </div>

          <div>
            <TsiText20
              :color="'gray'"
              :margBot="24"
              :margBotTab="24"
              :margBotMob="12"
              >Mobile 375 px.</TsiText20
            >
            <TsiBaseImage
              :cardColor="'white'"
              :imgUrl="require('@/assets/img_new/tsi/tsi_adaptive_mobile.svg')"
            ></TsiBaseImage>
          </div>
        </div>
      </TsiRightBlock>
    </TsiContainer>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiHeadText from "../UI/TsiHeadText.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText20 from "../UI/TsiText20.vue";
import TsiText80 from "../UI/TsiText80.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import TsiRightBlock from "../UI/TsiRightBlock.vue";
export default {
  components: {
    TsiContainer,
    TsiText16,
    TsiText20,
    TsiText80,
    TsiHeadText,
    TsiBaseImage,
    TsiRightBlock,
  },
};
</script>

<style scoped lang="sass">
.card__row
  display: flex
  margin: 0 -16px
  margin-bottom: 120px
  .card__col
    padding: 0 16px
    .card__item
      height: 100%
      &__logo
        margin-bottom: 32px

.flex__row
  display: flex
  justify-content: space-between

@media screen and (max-width: 1200px)
  .card__row
    flex-direction: column
    margin-bottom: 60px
    .card__col
      margin-bottom: 40px
      &:last-child
        margin-bottom: 0
@media screen and (max-width: 768px)
  .card__row
    margin-bottom: 40px
  .card__item
    padding: 20px
  .flex__row
    flex-direction: column
    div
      margin-bottom: 40px
      &:last-child
        margin-bottom: 0
</style>