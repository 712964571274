<template>
  <div class="background__white">
    <TsiContainer :padTop="120" :padTopTab="90" :padTopMob="60" :padBot="60" :padBotTab="40" :padBotMob="40">
      <TsiHeadText></TsiHeadText>
    </TsiContainer>
    <div class="aparts">
      <img class="aparts__image" :src="require('@/assets/img_new/tsi/tsi_house_map.png')" />
      <img class="aparts__hover" :src="require('@/assets/img_new/tsi/aparts__hover.png')" />
    </div>
    <div class="aparts__mobile">
      <img class="aparts__mobile__image" :src="require('@/assets/img_new/tsi/tsi_house_map.png')" />
      <img class="aparts__mobile__hover" :src="require('@/assets/img_new/tsi/select_home.svg')" />
      <img class="aparts__mobile__annotation" :src="require('@/assets/img_new/tsi/tsi_house_map__annotation.svg')" />
    </div>
    <TsiContainer :padBotTab="90" :padBotMob="60" class="pos_rel">
      <TsiText80 :margBot="60" :margBotTab="40" :margBotMob="40" :color="'black'">Планировка этажа</TsiText80>
      <TsiText16 :margBotTab="40" :margBotMob="40" :color="'black'">После выбора дома на генеральном плане, <br />
        пользователю предлагается выбрать подходящую квартиру на плане
        этажа.</TsiText16>
      <TsiBaseImageNoBorders v-if="isMobile == false" class="tsi__aparts__plan"
        :imgUrl="require('@/assets/img_new/tsi/tsi_plan.png')">
      </TsiBaseImageNoBorders>
      <TsiBaseImageNoBorders v-else class="tsi__aparts__plan"
        :imgUrl="require('@/assets/img_new/tsi/tsi_plan_mobile.png')">
      </TsiBaseImageNoBorders>
    </TsiContainer>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiHeadText from "../UI/TsiHeadText.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText20 from "../UI/TsiText20.vue";
import TsiText80 from "../UI/TsiText80.vue";
import TsiBaseImageNoBorders from "../UI/TsiBaseImageNoBorders.vue";
export default {
  components: {
    TsiContainer,
    TsiText16,
    TsiText20,
    TsiText80,
    TsiHeadText,
    TsiBaseImageNoBorders,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    window.innerWidth <= 768 ? (this.isMobile = true) : (this.isMobile = false);
  },
};
</script>

<style scoped lang="sass">
.background__white
  background-color: #FFF

.aparts__mobile
  display: none
.aparts
  position: relative
  margin-bottom: 120px
  &__image
    position: relative
    min-width: 1920px
    width: 100%
    left: 50%
    transform: translateX(-50%)
  &__hover
    position: absolute
    // top: 198px
    // left: 537px
    transition: all 0.3s ease
    opacity: 0
    min-width: 1920px
    width: 100%
    top: 0
    left: 50%
    transform: translateX(-50%)
  &__annotation
    position: absolute
    top: 50%
    left: 25%
    transition: all 1s ease
    opacity: 0

  &:hover
    .aparts
      &__hover
        transition: all 0.3s ease
        opacity: 1
      &__annotation
        transition: all 1s ease
        opacity: 1

@media screen and (max-width: 1200px)

  .aparts__mobile
      display: none
  .aparts
    transform: translateX(-15%)
    margin-bottom: 80px
    &__image
      left: 80%
    &__hover
      left: 80%
    &__annotation
      top: 35%
      left: 20%
  .tsi__aparts__plan
    img
      top: 0
      left: 0

@media screen and (max-width: 767px)
  .aparts
    display: none

  .aparts__mobile
    display: block
    position: relative
    margin-bottom: 60px
    &__image
      width: 767px
      max-width: none
    &__hover
      position: absolute
      top: -93px
      scale: 0.39
      left: 109px
      opacity: 0
      transition: all 0.3s ease
    &__annotation
      position: absolute
      top: -30px
      left: 15px
      scale: 0.4
      opacity: 0
    &:hover
      .aparts__mobile
        &__hover
          transition: all 0.3s ease
          opacity: 1
        &__annotation
          transition: all 1s ease
          opacity: 1
  .tsi__aparts__plan
    img
      top: 0
      width: 100%


@media screen and (max-width: 357px)
  .aparts__mobile
      position: relative
      &__image
        width: 160%
        max-width: none
      &__hover
        position: absolute
        top: -49%
        scale: 0.31
        left: 10%
      &__annotation
        position: absolute
        top: -65px
        left: -44px
        scale: 0.4

</style>