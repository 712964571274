import { render, staticRenderFns } from "./TsiContainer.vue?vue&type=template&id=1879a105&scoped=true&"
import script from "./TsiContainer.vue?vue&type=script&lang=js&"
export * from "./TsiContainer.vue?vue&type=script&lang=js&"
import style0 from "./TsiContainer.vue?vue&type=style&index=0&id=1879a105&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1879a105",
  null
  
)

export default component.exports