<template>
  <p
    :class="[textColor, centerTextClass]"
    :style="{ marginBottom: marginBotValue + 'px' }"
  >
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "margBotMob", "color", "center"],
  computed: {
    textColor() {
      if (this.color === "black") {
        return "black__text";
      }
      if (this.color === "yellow") {
        return "yellow__text";
      }
      if (this.color === "grey") {
        return "grey__text";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
    centerTextClass() {
      let className = this.center ? "center__text" : "";
      return className;
    },
  },
};
</script>

<style scoped lang='sass'>
p
  font-weight: 400
  font-size: 16px
  line-height: 24px
.yellow__text
  color: #F9AF34

.black__text
  color: #000000

.grey__text
  color: #8C8C8C

.center__text
  text-align: center

@media screen and (max-width: 1200px)
  p
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 24px
@media screen and (max-width: 768px)
  p
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
</style>