<template>
  <p :style="{ marginBottom: margBot + 'px' }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["margBot"],
};
</script>

<style scoped lang="sass">
p
  font-weight: 400
  font-size: 12px
  line-height: 18px
</style>