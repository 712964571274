<template>
  <div class="background__gray">
    <TsiContainer
      :padTop="120"
      :padTopTab="90"
      :padTopMob="60"
      :padBot="120"
      :padBotTab="90"
      :padBotMob="60"
    >
      <TsiText80
        :margBot="60"
        :margBotTab="40"
        :margBotMob="40"
        :color="'black'"
        >Другие страницы</TsiText80
      >
      <TsiHeadImageList
        v-if="!isMobile"
        :margBot="60"
        :data="otherPagesData"
      ></TsiHeadImageList>
      <div v-else>
        <TsiText42 :color="'black'" :margBot="12">Главная</TsiText42>
        <TsiBaseImage
          :color="'white'"
          :imgUrl="require(`@/assets/img_new/tsi/tsi_other_1.png`)"
          :margBotTab="40"
          :margBotMob="40"
          :clickable="true"
          @openImage="
            openPopupSlider(
              [require(`@/assets/img_new/tsi/fullSize/tsi_main_full.png`)],
              0
            )
          "
        ></TsiBaseImage>
        <TsiText42 :margBot="12" :color="'black'"
          >Страницы каталога квартир</TsiText42
        >
        <PhotoScroll
          class="photo__block"
          :clickable="true"
          :data="mobileScrolCatalog"
          :case="caseName"
        ></PhotoScroll>
        <TsiText42 :margBot="12" :color="'black'"
          >Страницы жилого комплекса</TsiText42
        >
        <PhotoScroll
          class="photo__block"
          :clickable="true"
          :data="mobileScrolComplex"
          :case="caseName"
        ></PhotoScroll>
        <TsiText42 :margBot="12" :color="'black'"
          >Второстепенные страницы</TsiText42
        >
        <PhotoScroll
          class="photo__block"
          :clickable="true"
          :data="mobileScrolSecondary"
          :case="caseName"
        ></PhotoScroll>
      </div>
    </TsiContainer>
    <ImagesPopupSlider ref="imagesPopupSlider"></ImagesPopupSlider>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiText42 from "../UI/TsiText42.vue";
import TsiText80 from "../UI/TsiText80.vue";
import TsiHeadImageList from "../UI/TsiHeadImageList.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import PhotoScroll from "@/components/app/PhotoScroll.vue";
import ImagesPopupSlider from "@/components/app/ImagesPopupSlider";

export default {
  components: {
    TsiContainer,
    TsiText42,
    TsiText80,
    TsiHeadImageList,
    TsiBaseImage,
    PhotoScroll,
    ImagesPopupSlider,
  },
  data() {
    return {
      isMobile: false,
      caseName: "caseTsi",
      mobileScrolCatalog: {
        clickable: true,
        imageBorder: true,
        images: [
          {
            image: require(`@/assets/img_new/tsi/tsi_other_2.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_1.png`),
            desc: "Выбор квартиры на карте",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_3.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_2.png`),
            desc: "Выбор дома",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_4.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_3.png`),
            desc: "Выбор квартиры плиткой",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_5.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_4.png`),
            desc: "Выбор квартиры списком",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_6.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_6.png`),
            desc: "Планировка этажа",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_7.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_flat_5.png`),
            desc: "Квартира детальная",
          },
        ],
      },
      mobileScrolComplex: {
        clickable: true,
        imageBorder: true,
        images: [
          {
            image: require(`@/assets/img_new/tsi/tsi_other_8.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_complex_1.png`),
            desc: "Выбор ЖК на карте",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_9.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_complex_2.png`),
            desc: "Выбор ЖК списком",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_10.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_complex_3.png`),
            desc: "Жилые комлпексы (детальная)",
          },
        ],
      },
      mobileScrolSecondary: {
        clickable: true,
        imageBorder: true,
        images: [
          {
            image: require(`@/assets/img_new/tsi/tsi_other_11.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_1.png`),
            desc: "О компании",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_12.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_2.png`),
            desc: "Вакансии",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_13.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_3.png`),
            desc: "Акции детальная",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_14.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_4.png`),
            desc: "Ипотека",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_15.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_5.png`),
            desc: "Новости",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_16.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_6.png`),
            desc: "Новость детальная",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_17.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_7.png`),
            desc: "Акции",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_18.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_8.png`),
            desc: "Контакты",
          },
          {
            image: require(`@/assets/img_new/tsi/tsi_other_19.png`),
            fullImage: require(`@/assets/img_new/tsi/fullSize/tsi_page_9.png`),
            desc: "Избранное",
          },
        ],
      },
      otherPagesData: [
        {
          id: 1,
          type: 1,
          head: "Главная",
          page: { img: "tsi_other_1.png" },
          imgPopup: [
            require(`@/assets/img_new/tsi/fullSize/tsi_main_full.png`),
          ],
        },
        {
          id: 2,
          type: 2,
          head: "Страницы каталога квартир",
          page: [
            {
              img: "tsi_other_2.png",
              text: "Выбор квартиры на карте",
            },
            {
              img: "tsi_other_3.png",
              text: "Выбор дома",
            },
            {
              img: "tsi_other_4.png",
              text: "Выбор квартиры плиткой",
            },
            {
              img: "tsi_other_5.png",
              text: "Выбор квартиры списком",
            },
            {
              img: "tsi_other_6.png",
              text: "Планировка этажа",
            },
            {
              img: "tsi_other_7.png",
              text: "Квартира детальная",
            },
          ],
          imgPopup: [
            require("@/assets/img_new/tsi/fullSize/tsi_flat_1.png"),
            require("@/assets/img_new/tsi/fullSize/tsi_flat_2.png"),
            require(`@/assets/img_new/tsi/fullSize/tsi_flat_3.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_flat_4.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_flat_6.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_flat_5.png`),
          ],
          centerDesc: true,
        },
        {
          id: 3,
          type: 2,
          head: "Страницы жилого комплекса",
          page: [
            { img: "tsi_other_8.png", text: "Выбор ЖК на карте" },
            { img: "tsi_other_9.png", text: "Выбор ЖК списком" },
            { img: "tsi_other_10.png", text: "Жилые комлпексы (детальная)" },
          ],
          imgPopup: [
            require(`@/assets/img_new/tsi/fullSize/tsi_complex_1.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_complex_2.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_complex_3.png`),
          ],
          centerDesc: true,
        },
        {
          id: 4,
          type: 2,
          head: "Второстепенные страницы",
          page: [
            { img: "tsi_other_11.png", text: "О компании" },
            { img: "tsi_other_12.png", text: "Вакансии" },
            { img: "tsi_other_13.png", text: "Акции детальная" },
            { img: "tsi_other_14.png", text: "Ипотека" },
            { img: "tsi_other_15.png", text: "Новости" },
            { img: "tsi_other_16.png", text: "Новость детальная" },
            { img: "tsi_other_17.png", text: "Акции" },
            { img: "tsi_other_18.png", text: "Контакты" },
            { img: "tsi_other_19.png", text: "Избранное" },
          ],
          imgPopup: [
            require(`@/assets/img_new/tsi/fullSize/tsi_page_1.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_2.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_3.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_4.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_5.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_6.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_7.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_8.png`),
            require(`@/assets/img_new/tsi/fullSize/tsi_page_9.png`),
          ],
          centerDesc: true,
        },
      ],
    };
  },
  mounted() {
    window.innerWidth <= 768 ? (this.isMobile = true) : (this.isMobile = false);
  },
  methods: {
    openPopupSlider(imgUrl, index) {
      this.$refs.imagesPopupSlider.openPopupSlider(imgUrl, index);
    },
  },
};
</script>

<style scoped lang="sass">
.background__gray
  background-color: #F5F5F5

.photo__block
  margin-bottom: 40px
</style>