<template>
  <div class="background__white">
    <div style="position: relative; z-index: 5">
      <TsiContainer
        :padTop="120"
        :padTopTab="90"
        :padTopMob="60"
        :padBot="120"
        :padBotTab="90"
        :padBotMob="60"
      >
        <img
          class="decor__right"
          :src="require('@/assets/img_new/tsi/tsi-icon-vertical.svg')"
        />
        <TsiText80
          :margBot="60"
          :margBotTab="40"
          :margBotMob="40"
          :color="'yellow'"
          >Карточка квартиры</TsiText80
        >
        <TsiRightBlock :margBot="60" :margBotTab="40" :margBotMob="40">
          <TsiText16 :color="'black'">
            Переход на детальную страницу карточки квартиры мы постарались
            сделать максимально коротким: пользователь с главной страницы
            воспользовался подбором квартир — получил список квартир — перешел
            на детальную страницу подходящего объекта.
          </TsiText16>
        </TsiRightBlock>
        <div class="layer__up">
          <TsiBaseImage
            :imgUrl="require('@/assets/img_new/tsi/tsi_card.png')"
            :cardColor="'white'"
            :shadows="true"
          ></TsiBaseImage>
        </div>
        <div class="left__text">
          <TsiText16 :color="'black'">
            В карточке квартиры выводятся: изображения планировки, на этаже, на
            ген.плане. Параметры квартиры. Есть возможность распечатать в PDF. И
            апогеем всего пользовательского пути служит кнопка «Забронировать».
            После отправки формы брони - данные пользователя отправляются в CRM
            отделу продаж «ТСИ».
          </TsiText16>
        </div>
      </TsiContainer>
    </div>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiRightBlock from "../UI/TsiRightBlock.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText80 from "../UI/TsiText80.vue";
export default {
  components: {
    TsiContainer,
    TsiText16,
    TsiText80,
    TsiRightBlock,
    TsiBaseImage,
  },
};
</script>

<style scoped lang="sass">
.background__white
  background-color: #FFF

.decor__right
  position: absolute
  top: 120px
  right: -300px
  z-index: 1

.layer__up
  position: relative
  z-index: 5
  background-color: #FFF
  margin-bottom: 60px

.left__text
  width: 80%

@media screen and (max-width: 1200px)
  .decor__right
    display: none
  
  .left__text
    width: 100%

@media screen and (max-width: 767px)
  .layer__up
    margin-bottom: 40px
</style>