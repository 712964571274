<template>
  <div class="background">
    <div style="position: relative; z-index: 5">
      <TsiContainer :padTop="120" :padTopTab="80" :padTopMob="60">
        <img
          class="decor"
          :src="require('@/assets/img_new/tsi/tsi-icon-vertical.svg')"
        />
        <TsiTextMain :margBot="60" :margBotTab="32" :margBotMob="32">
          Разработали <span class="yellow__text">новый сайт девелопера</span>,
          который объединяет в себе лендинговые страницы для каждого ЖК, каталог
          квартир, коммерческих помещений и парковок с фильтрами и выбором на
          ген.плане.
        </TsiTextMain>
        <TsiRightBlock :margBot="120" :margBotTab="90" :margBotMob="60">
          <div>
            <TsiText16
              :margBot="12"
              :margBotTab="12"
              :margBotMob="12"
              class="yellow__text"
              ><a href="https://tsirt.ru" target="_blank"
                >tsirt.ru</a
              ></TsiText16
            >
            <TsiText16 :margBot="12" :margBotTab="12" :margBotMob="12"
              >срок разработки: 4 месяца</TsiText16
            >
            <TsiText16 :margBot="60" :margBotTab="40" :margBotMob="40"
              >запуск: 01.02.2022 г.</TsiText16
            >
          </div>
          <div class="card">
            <div
              class="card__item wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <TsiText20 :margBot="16" :margBotTab="16" :margBotMob="16"
                >Задача</TsiText20
              >
              <TsiText16 :margBot="60" :margBotTab="32" :margBotMob="16">
                У нашей компании достаточно много проектов и нам необходим сайт,
                которые будет объединять в себе все выполненные проекты,
                выводить актуальный список квартир, продающиеся в наших домах.
              </TsiText16>
              <div class="card__item__manager">
                <img :src="require('@/assets/img_new/tsi/avatar_tsi.png')" />
                <div class="card__item__manager__text">
                  <TsiText16 :margBot="4">Гульнара Салахиева</TsiText16>
                  <TsiText12>Маркетолог ТСИ</TsiText12>
                </div>
              </div>
            </div>

            <div
              class="card__item wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <TsiText20 :margBot="16" :margBotTab="16" :margBotMob="16"
                >Решение</TsiText20
              >
              <TsiText16 :margBot="60" :margBotTab="32" :margBotMob="16">
                Выполнили предпроектную аналитику, проанализировали конкурентов.
                Нарисовали современный дизайн, продумали удобную структура
                сайта. Запрограммировали логику и синтегрировали каталог
                объектов с 1С.
              </TsiText16>
              <div class="card__item__manager">
                <img
                  :src="require('@/assets/img_new/tsi/avatar_artrockets.png')"
                />
                <div class="card__item__manager__text">
                  <TsiText16 :margBot="4">Диана Белова</TsiText16>
                  <TsiText12>Проект-менеджер Артрокетс</TsiText12>
                </div>
              </div>
            </div>
          </div>
        </TsiRightBlock>
        <div class="tsi__analytics">
          <TsiText80 :margBot="60" :margBotTab="60" :margBotMob="40"
            >Предпроектная аналитика</TsiText80
          >
          <TsiText16 :margBot="60" :margBotTab="60" :margBotMob="40"
            >Продумали и сформировали удобную структуру сайта. Учитывали
            пользовательские пути по подбору объектов недвижимости.
          </TsiText16>
          <PhotoScroll
            :clickable="false"
            :data="toScroll"
            :imgWidth="5000"
            :case="toScroll.case"
          >
          </PhotoScroll>
        </div>
        <TsiText80 :margBot="60" :margBotTab="60" :margBotMob="40"
          >Главная страница</TsiText80
        >
        <TsiBaseImage
          :imgUrl="require('@/assets/img_new/tsi/tsi_main.png')"
          :margBot="120"
          :margBotTab="90"
          :margBotMob="60"
        ></TsiBaseImage>

        <TsiTextMain :color="'black'">
          <span class="yellow__text">Главная страница</span> — это основная
          разводящая страница на сайте. Объединяет в себе список жилых
          комплексов (проектов), фильтр подбора объектов недвижимости, акции и
          краткую информацию о компании.
        </TsiTextMain>
      </TsiContainer>
    </div>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiTextMain from "../UI/TsiTextMain.vue";
import TsiRightBlock from "../UI/TsiRightBlock.vue";
import TsiText12 from "../UI/TsiText12.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText20 from "../UI/TsiText20.vue";
import TsiText80 from "../UI/TsiText80.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import PhotoScroll from "@/components/app/PhotoScroll.vue";

export default {
  components: {
    TsiContainer,
    TsiTextMain,
    TsiRightBlock,
    TsiText12,
    TsiText16,
    TsiText20,
    TsiText80,
    TsiBaseImage,
    PhotoScroll,
  },
  data() {
    return {
      toScroll: {
        case: "caseTsi",
        clickable: false,
        images: [
          {
            image: require(`@/assets/img_new/tsi/scheme_png.png`),
            fullImage: require(`@/assets/img_new/tsi/scheme_png.png`),
          },
        ],
      },
    };
  },
  mounted() {
    const slider = document.querySelector(".vue-slider");
    const sliderProgress = document.querySelector(".vue-slider-process");
    const sliderElement = document.querySelector(".vue-slider-dot-handle");

    slider.style.backgroundColor = "#FFFFFF !important";
    sliderProgress.classList.add("vue-slider-process__tsi");
  },
};
</script>

<style lang="sass">
.background
  background-color: #F5F5F5 !important
  position: relative
  &:before
    content: ''
    position: absolute
    top: 0
    background-color: #505050 !important
    width: 100%
    height: 80%

.tsi__analytics
  margin-bottom: 140px

.decor
  position: absolute
  top: 130px
  left: -550px
.card
  display: flex
  justify-content: space-between
  &__item
    flex-basis: 45%
    padding: 40px
    border: 1px solid #A7ABAF
    &__manager
      display: flex
      align-items: center
      img
        width: 60px
        height: 60px
        margin-right: 24px
        border-radius: 4px
        &__text
          display: flex
          flex-direction: column
          justify-content: center

@media screen and (max-width: 1200px)
  .card
    flex-direction: column
    &__item
      width: 100%
      margin-bottom: 40px
      &:last-child
        margin-bottom: 0
  .tsi__analytics
    margin-bottom: 90px

@media screen and (max-width: 768px)
  .background
    &:before
      height: 85%
  .tsi__analytics
    margin-bottom: 60px

  .card__item
    padding: 32px 20px
    margin-bottom: 32px
    &:last-child
      margin-bottom: 0
</style>