<template>
  <div :style="{ marginBottom: marginBotValue + 'px' }">
    <div class="head__image" v-for="(item, index) in data" :key="item.id">
      <div class="head__image__block" v-if="item.type == 1">
        <div class="blockLeftMenu__left">
          <div class="blockLeftMenu__left__content">
            <TsiText42 class="head__image__block__head" :color="'black'">{{
              item.head
            }}</TsiText42>
          </div>
        </div>
        <div class="head__image__block__row" @click="openPopupSlider(index, index)">
          <TsiBaseImage
            :cardColor="'white'"
            class="head__image__block__image"
            :imgUrl="require(`@/assets/img_new/tsi/${item.page.img}`)"
            :clickable="true"
          ></TsiBaseImage>
        </div>
      </div>

      <div class="head__image__block__2" v-if="item.type == 2">
        <div class="blockLeftMenu__left">
          <div class="blockLeftMenu__left__content">
            <TsiText42 class="head__image__block__head" :color="'black'">{{
              item.head
            }}</TsiText42>
          </div>
        </div>
        <div class="head__image__block__2__row">
          <div
            class="head__image__block__2__col"
            v-for="(page, index) in item.page"
            :key="index"
            @click="openPopupSlider(item.id, index)"
          >
            <TsiBaseImage
              :margBot="16"
              :margBotTab="16"
              :margBotMob="16"
              :cardColor="'white'"
              class="head__image__block__2__image"
              :imgUrl="require(`@/assets/img_new/tsi/${page.img}`)"
              :clickable="true"
            ></TsiBaseImage>
            <TsiText16 :center="item.centerDesc" :color="'grey'">{{
              page.text
            }}</TsiText16>
          </div>
        </div>
      </div>
    </div>
    <ImagesPopupSlider ref="imagesPopupSlider"></ImagesPopupSlider>
  </div>
</template>

<script>
import TsiRightBlock from "./TsiRightBlock.vue";
import TsiBaseImage from "./TsiBaseImage.vue";
import TsiText16 from "./TsiText16.vue";
import TsiText42 from "./TsiText42.vue";
import ImagesPopupSlider from "@/components/app/ImagesPopupSlider";

export default {
  props: ["margBot", "margBotTab", "margBotMob", "data"],
  components: {
    TsiText16,
    TsiText42,
    TsiRightBlock,
    TsiBaseImage,
    ImagesPopupSlider,
  },
  computed: {
    blockType() {
      if (this.data.type == 1) {
        return "oneImg";
      } else {
        return "twoImg";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
  methods: {
    openPopupSlider(index, imgIndex) {
      let indexForSearch;
      index > 0 ? (indexForSearch = index - 1) : (indexForSearch = index);

      let imgToOpen = this.data[indexForSearch].imgPopup;

      // if (imgIndex == 0) {
      //   imgIndex += 1;
      // }
      this.$refs.imagesPopupSlider.openPopupSlider(imgToOpen, imgIndex);
    },
  },
  mounted() {
    leftMenuItems = document.querySelectorAll(".blockLeftMenu__left__content");
    window.onscroll = function () {
      leftMenuItemsPosition();
    };
  },
};

var leftMenuItems = null;
function leftMenuItemsPosition() {
  leftMenuItems.forEach((item) => {
    var parent = item.closest(".blockLeftMenu__left");
    var parentTop =
      parent.getBoundingClientRect().top + document.documentElement.scrollTop;
    var parentLeft = parent.offsetLeft;
    var parentRight = parent.offsetRight;
    var parentHeight = parent.offsetHeight;
    var parentWidth = parent.offsetWidth;
    var itemTop = item.offsetTop;
    var scrollTop = window.pageYOffset;
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    var top = 78;

    if (windowWidth < 1440) {
      top = 70;
    }

    if (scrollTop + item.offsetHeight >= parentTop + parentHeight - top) {
      item.style.top = "auto";
      item.style.bottom = "0px";
      item.style.position = "absolute";

      item.style.width = parentWidth + "px";
      item.style.left = "";
    } else if (scrollTop + top < parentTop) {
      item.style.top = "";
      item.style.bottom = "";
      item.style.position = "";
      item.style.width = "";
      item.style.left = "";
    } else {
      item.style.top = top + "px";
      item.style.bottom = "auto";
      item.style.position = "fixed";

      // if (item.closest(".mpk__navigation__2") != null) {
      //   item.style.left =
      //     parentLeft + parent.getBoundingClientRect().left + "px";
      //   item.style.textAlign = "right";
      //   item.style.width = parentWidth + "px";
      // } else if (item.closest(".mpk_projects")) {
      //   item.style.width = parent.getBoundingClientRect().width - 20 + "px";
      // } else {
      //   // item.style.left = parentLeft + "px";
      //   item.style.left = parent.getBoundingClientRect().left + 25 + "px";
      //   item.style.width = parentWidth + "px";
      // }
    }

    // if (item.closest(".mpk__info")) {
    //   let mainContainer = item.closest(".mpk__info");
    //   let container = item.closest(".container3");
    //   let mainContainerHeight = mainContainer.offsetHeight;
    //   let containerHeight = container.offsetHeight;

    //   let differenceHeight = containerHeight - mainContainerHeight;
    //   if (windowWidth > 1200) {
    //     parent.style.marginBottom = differenceHeight + "px";
    //   }
    // }
  });
}
</script>

<style scoped lang="sass">
.head__image
  margin-bottom: 60px
.head__image__block
  display: flex
  justify-content: space-between
  &__row
    width: 790px
  &__head
    margin-right: 32px
    max-width: 380px
    width: 380px
    &__image
      // max-width: 780px
      flex-basis: 50%

.head__image__block__2
  display: flex
  justify-content: space-between
  margin-bottom: 60px
  &:last-child
    margin-bottom: 0
  &__row
    display: flex
    width: 100%
    max-width: 820px
    margin: 0 -15px
    flex-wrap: wrap
    margin-bottom: -32px
  &__col
    padding: 0 15px
    flex-basis: 50%
    margin-bottom: 32px

  &__block
    display: flex
    &__image
      max-width: 380px

.blockLeftMenu__left
  position: relative
  min-width: 380px

@media screen and (max-width: 1200px)
  .head__image__block
    justify-content: flex-start
    flex-direction: column
    margin-bottom: 60px
    &__row
      width: 100%
    &__head
      margin-right: 0
      max-width: none
      width: auto
      margin-bottom: 40px

  .head__image__block__2
    flex-direction: column
    margin-bottom: 40px
    &:last-child
      margin-bottom: 0
    &__row
      display: flex
      width: auto
      max-width: 1200px
      margin: 0 -12px
      flex-wrap: wrap
      margin-bottom: -24px
    &__col
      padding: 0 12px
      flex-basis: 50%
      margin-bottom: 24px

    &__block
      display: flex
      &__image
        max-width: 380px

@media screen and (max-width: 767px)
  .head__image
    margin-bottom: 40px
  .head__image__block
    margin-bottom: 40px
    &__head
      margin-bottom: 20px
</style>