<template>
  <div class="banner setMenuColor background-dark" data-menu-color="light">
    <TsiContainer class="banner__text">
      <TsiText80 :margBot="32" :margBotTab="32" :margBotMob="16" :banner="true"
        >СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК «ТСИ»</TsiText80
      >
      <TsiText16>
        Разработка сайта для крупного застройщика Республики Татарстан
      </TsiText16>
    </TsiContainer>
    <div class="banner__video">
      <video
        :src="require('@/assets/img_new/tsi/video/tsi_video.mp4')"
        playsinline
        autoplay
        muted
        loop
        ref="sliderVideo"
      ></video>
    </div>
  </div>
</template>

<script>
import TsiContainer from "../UI/TsiContainer.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText80 from "../UI/TsiText80.vue";
export default {
  components: { TsiContainer, TsiText16, TsiText80 },
  methods: {
    bannerVideoSize: function () {
      var comp = this;
      setTimeout(function () {
        var video = comp.$refs["sliderVideo"];
        var parent = video.closest(".banner__video");
        video.style.height = "auto";
        video.style.width = "auto";

        var containerHeight = parent.offsetHeight;
        var containerWidth = parent.offsetWidth;
        var videoHeight = video.offsetHeight;
        var videoWidth = video.offsetWidth;

        if (containerHeight / containerWidth > containerWidth / videoWidth) {
          video.style.height = containerHeight + 10 + "px";
        } else {
          video.style.width = containerWidth + 10 + "px";
        }
      });
    },
  },
  mounted() {
    let comp = this;
    window.addEventListener("resize", function () {
      comp.bannerVideoSize();
    });
  },
};
</script>

<style scoped lang="sass">
.background-dark
  background-color: #505050

.banner
  height: 100vh
  max-height: 1050px
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  &__text
    z-index: 5
    display: flex
    flex-direction: column
    align-items: flex-start
  &__video
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0
    overflow: hidden
    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: linear-gradient(180deg, rgba(0,0,0,1) 0%,  rgba(0, 0, 0, 0) 58.8%)
    video
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
</style>