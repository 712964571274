<template>
  <p
    :class="[textColor, bannerText]"
    :style="{ marginBottom: marginBotValue + 'px' }"
  >
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "margBotMob", "color", "banner"],
  computed: {
    textColor() {
      if (this.color === "black") {
        return "black__text";
      }
      if (this.color === "yellow") {
        return "yellow__text";
      }
    },
    bannerText() {
      if (this.banner && window.innerWidth < 767) {
        return "bannerText";
      }
    },
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
};
</script>

<style scoped lang="sass">
p
  font-weight: 900
  font-size: 80px
  line-height: 90px

.yellow__text
  color: #F9AF34

.black__text
  color: #000000

.bannerText
  font-size: 20px !important
  line-height: 28px

@media screen and (max-width: 1200px)
  p
    font-weight: 900
    font-size: 50px
    line-height: 62px

@media screen and (max-width: 767px)
  p
    font-weight: 900
    font-size: 34px
    line-height: 48px
    overflow-wrap: break-word
</style>