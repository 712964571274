<template>
  <p :class="textColor" :style="{ marginBottom: margBot + 'px' }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["margBot", "color"],
  computed: {
    textColor() {
      if (this.color === "black") {
        return "black__text";
      }
      if (this.color === "yellow") {
        return "yellow__text";
      }
    },
  },
};
</script>

<style scoped lang="sass">
p
  font-weight: 900
  font-size: 42px
  line-height: 54px

.yellow__text
  color: #F9AF34

.black__text
  color: #000000

@media screen and (max-width: 1200px)
  p
    font-weight: 700
    font-size: 32px
    line-height: 40px
@media screen and (max-width: 768x)
  p
    font-style: normal
    font-weight: 700
    font-size: 24px
    line-height: 32px
</style>