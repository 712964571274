<template>
  <div class="head__text__block" :style="{ marginBottom: margBot + 'px' }">
    <TsiText80
      :margBotTab="40"
      :margBotMob="40"
      class="head__text__block__head"
      :color="'black'"
      ><span class="yellow__text">Интерактивный</span><br />
      выбор дома</TsiText80
    >
    <TsiText16 class="head__text__block__text" :color="'black'"
      >Разработали интерактивный выбор дома на генеральном плане жилого
      комплекса.
    </TsiText16>
  </div>
</template>

<script>
import TsiRightBlock from "../UI/TsiRightBlock.vue";
import TsiBaseImage from "../UI/TsiBaseImage.vue";
import TsiText16 from "../UI/TsiText16.vue";
import TsiText80 from "../UI/TsiText80.vue";
export default {
  props: ["margBot"],
  components: {
    TsiText16,
    TsiText80,
    TsiRightBlock,
    TsiBaseImage,
  },
};
</script>

<style scoped lang="sass">
.head__text__block
  display: flex
  align-items: center
  justify-content: center
  &__head
    margin-right: 48px
    &__text
      position: relative
      top: -22px

@media screen and (max-width: 1200px)
  .head__text__block
    display: flex
    flex-direction: column
    align-items: flex-start
    &__head
      margin-right: 0
    &__text
      position: relative
      top: 0
</style>